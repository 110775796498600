.FiltersBottomDrawer {
    background: rgb(255, 255, 255);
    position: fixed;
    bottom: 0;
    // left: 0;
    padding: 1rem;
    width: 100%;
    border-radius: 32px 32px 0px 0px;
    z-index: 9999;
    // left: 50%;
    // transform: translate(-50%, 0px);
    overflow-y: auto;

    p {
        margin-bottom: 0;
        font-size: 14px;
    }

    input {
        border-color: gray;
    }

    .current-location>p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .filter-label {
        font-style: normal;
        font-size: 14px;
        line-height: 14px;
        &.active {
            font-weight: 600;
        }
    }

}

.close-icon-btn {
    position: absolute;
    top: 0.5rem;
    left: 1rem;
    background: #fff !important;
    outline: none !important;
    border: none !important;
}

#overlay {
    position: fixed;
    // display: none;
    width: 100%;
    height: 100%;
    top: 0;
    // left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
}


@media only screen and (min-width: 600px) {
    .confirm-address-wrapper {
        left: 50%;
        transform: translateX(-50%);
    }
}

@media only screen and (min-width: 992px) {
    .FiltersBottomDrawer {
        width: 20vw !important;
        height: 100vh;
        background: rgb(255, 255, 255);
        padding: 1rem;
        border-radius: 0px;
        // left: 0% !important;
        right: 0;
        transform: translate(0, 0);
    }
}