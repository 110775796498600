
.ProductDetails .alert-dismissible.show {
    position: fixed;
    width: 100%;
    z-index: 9999;
    height: 60px;
    margin-left: -0.75rem;

    .info-text,  .danger-text{
        font-size: 14px;
    }
}