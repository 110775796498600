.homepage-settings{
  .dropdown-menu{
    border: none;
    position: absolute;
    width: 375px;
    // left: -343px !important;
    top: 0.5rem !important;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  
  .settings-dropdown{
    hr{
      width: 348px;
      height: 0px;
      border: 0.5px solid rgba(205, 199, 199, 0.2);
      margin: 5px;
      opacity: 1;
    }

    .icon-right-arrow{
      justify-content: flex-end;
    }
  }

  .dropdown-divider{
    border-top: 2px solid #F1F1F1;
    border-bottom: none;
  }

  .dropdown-item{
    span{
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #000000;
    }

    .arrow-right{
      border: solid #457D45;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(-45deg);
    }
  }
}