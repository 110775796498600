.verify-user {
  .form-control {
    background-color: #eee !important;
  }

  .make-sure-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
}

.signup {
  p {
    font-size: 12px;
    line-height: 12px;
  }

  a {
    color: var(--gold-light);
  }
}

.Onboarding {
  h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 42px;
    display: flex;
    align-items: center;
    color: #343434;
  }

  .text-style {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #343434;
  }
}

@media only screen and (max-width: 576px) {
  .Onboarding {
    .wrapper {
      height: 100vh;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 2rem;
    }
  }
}