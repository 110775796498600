.payment-items-wrapper{

    .input-form-text{
        margin-bottom: 1.25rem;
        position: relative;
        label {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: var(--color-black);
        }
        .form-control {
          height: 30px;
          width: 327px;
          color: rgb(13, 12, 12) !important;
          &.active {
            background-color: #fff;
          }
        }
        .form-label {
          width: 100%;
          overflow-x: clip;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
    }
}


.payment-header {
    position: sticky;
    top: 0;
    z-index: 999;
    background: #eee;

    .btn-empty {
        background: #CB9F47;
        width: 100%;
        outline: none !important;
        border: none !important;
    }
}

.payment-subtotal-details {
    padding-bottom: 5rem;

    .price-item {
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #343434;
        }
    }

    .total-price-item {
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 24px;
        color: #343434;
    }
}

.btn-paynow {
    background: #343434 !important;
    width: 100%;
    outline: none !important;
    border: none !important;
}

.btn-validate {
    background: #DBBE82 !important;
    outline: none !important;
    border: none !important;
}

.visa-card {
    .form-control {
        background-image: url("../../assets/icons/visa-icon.svg");
    }
    .form-control .is-invalid {
        background-image: none
    }
}

.payment-form {
    .invalid-feedback {
        font-size: 11px;
    }
}

