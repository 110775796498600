.container.produts-wrapper {
    padding: 0;
}

/** PRODUCTS SCROLL ONLY INSTEAD WHOLE PAGE */
.container.produts-wrapper {
    height: 100vh;
    overflow: hidden;

    .products {
        height: 63vh;
        overflow-y: auto;
    }
}

/* END */

.produts-wrapper,
.products {
    background: #EFEFEF;
    // max-width: 396px;
    margin: auto;
}

.top-10-products {
    height: 10rem;
    flex-wrap: nowrap !important;
    overflow-x: auto;

    .card {
        height: 134px;

        .card_header {
            height: 40px !important;
        }

        .card-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .desc {
            font-weight: 500;
            font-size: 10px;
            line-height: 10px;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            color: #A0A0A0;
        }

        .name {
            font-style: normal;
            font-weight: 800;
            font-size: 14px;
            line-height: 14px;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            text-align: center;
            color: #343434;
        }

        .price {
            font-style: normal;
            font-weight: 800;
            font-size: 14px;
            line-height: 14px;
            align-items: center;
            text-align: center;
            color: #CB9F47;
        }
    }
}

.store-detail {
    // height: 200px;
    z-index: 9999;

    left: 0px;
    top: 0px;
    background: #343434;
    // border-bottom-right-radius: 2.5rem;
    // border-bottom-left-radius: 2.5rem;
    // margin-bottom: 1.5rem;

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #CACACA;
        margin-bottom: 0.25rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .name {
        color: #DBBE82;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .store-logo {
        display: flex;
        align-items: center;

        img {
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
        }
    }

    .review_url {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        /* line-height: 16px; */
        color: #CB9F47;
        text-decoration: none;
    }

    .favorite-btn,
    .btn:disabled,
    .favorite-btn:hover,
    .btn:active,
    .btn:focus {
        background: transparent !important;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
    }

    .cart-item-count {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 4px;
        position: absolute;
        width: 1rem;
        height: 1rem;
        right: -5px;
        top: -5px;
        background: #CB9F47;
        border-radius: 100px;

        span {
            font-style: normal;
            font-weight: 500;
            font-size: 0.5rem;
            line-height: 0.5rem;
            text-align: center;
            color: #FFFFFF;
        }
    }

}

.store-detail.secondary-header {
    height: 4rem;
    // position: sticky;
    // top: 0;
    z-index: 9999;
    background: #eee;
    .favorite-btn {
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
    }
}

.filters-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 1rem;
    flex-wrap: nowrap;
    overflow-x: auto;

    .btn {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: #343434;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .search-wrapper {
        position: relative;
        margin-top: 1.5rem;
        margin-left: 10px;
        margin-right: 10px;

        .search-box {
            padding-left: 2rem;
        }

        img {
            position: absolute;
            top: 0.5rem;
            left: 0.75rem;
        }
    }
}

.chip {
    flex-wrap: nowrap;
    width: 100%;
    // margin-bottom: 1rem;
    overflow: auto;

    &::-webkit-scrollbar {
        display: none
    }

    ;

    .btn {
        width: 100%;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: #343434;
        white-space: nowrap;
        overflow: hidden;
    }

    .btn.active {
        background: #343434;
        color: #fff;
    }
}



@media only screen and (max-width: 599px) {
    .search-wrapper {
        margin-top: 1rem;
    }

    .no-products-found {
        font-size: 12px;
    }
}

@media only screen and (min-width: 600px) {
    .all-products {
        .product>.card {
            // min-width: 300px;
            height: 240px;
        }
    }
}