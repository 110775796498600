.Description {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;
    padding-bottom: 2rem;
    // position: absolute;
    position: relative;
    // width: 100vw;
    min-height: calc(100vh - 50vh);

    bottom: 0px;
    background: #FFFFFF;
    border-radius: 32px 32px 0px 0px;

    .variation-details {
        color: #343434;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 20px;
    }

    .deal-message {
        display: flex;
        padding: 2px 6px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        font-size: 14px;
        font-weight: 500;
        align-self: stretch;
        border-radius: 4px;
        background: #FB8D8D;
    }

    .label-text {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: #343434 !important;
        font-weight: 700;
    }

    .flavour {
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 20px;
        color: #343434;
    }

    .price {
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 24px;
        color: #CB9F47;
    }

    .nicotine-strength,
    .description {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #343434;
    }

    .label-flavours {
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 14px;
        color: #343434;
    }

    .flavour-selected-text {
        font-size: 12px;
        line-height: 12px;
    }

    .flavours-btn {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 1rem;
        overflow: auto;

        .btn {
            padding: 0.25rem 0.5rem;
        }

        .flavour-selected {
            background: #343434 !important;
            color: #fff;
        }

        .flavour-selected.label-absent {
            display: none;
        }
    }

    .related-items-wrapper {
        overflow-x: auto;
        gap: 1.5rem;

        .related-item {
            border: 2px solid #eee;
            border-radius: 0.5rem;
            cursor: pointer;
        }
    }
}

.Header {
    background: #fff;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    border-bottom: 1px solid #eee;
    position: sticky;
    top: 0;
    padding: 50px;
    z-index: 999;

    .heart-btn {
        padding: 0;
        background: #fff;
        border: none;
        outline: none;
        width: 24px;
        height: 24px;

        img {
            width: 100%;
            height: 100%;
        }


        &:hover,
        &.btn:disabled {
            background: #fff !important;
        }
    }

    .cart-icon-wrapper {
        position: relative;
        width: 60%;
        cursor: pointer;
    }

    .cartItemTotal {
        position: absolute;
        top: -8px;
        left: 1rem;
        background: #0288D1;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        color: #fff;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.Footer {
    // position: fixed;
    // bottom: 0;
    // width: 100%;
    padding: 1rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(202, 202, 202, 0.5);
    // border-radius: 20px 20px 0px 0px;
    height: 6rem;

    // .foolter-col {
    //     display: flex;
    //     align-items: center;
    // }
    .out-of-stock-text {
        margin-bottom: 0.5rem !important;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        /* display: flex; */
        align-items: center;
        text-align: center;
        color: #CB9F47;
        text-align: center;
    }

    .add-to-cart-btn,
    .inc-dec-btn {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 44px;
    }

    .add-to-cart-btn>.btn {
        padding: 10px 16px;
        gap: 8px;
        width: 100%;
        height: 44px;
        // background: #A0A0A0;
        background: #343434;
        border-radius: 8px;
    }

    .inc-dec-btn {
        border: 2px solid #EFEFEF;
        border-radius: 8px;
        height: 44px;
    }

    .inc-dec-btn>.btn {
        background-color: #FFFFFF;
        outline: none;
        border: none;
        color: #000;

    }
}

.ProductDetails {
    min-height: 100vh;
    // overflow: hidden;
}

.ImageSlider {
    .slick-list {
        height: 240px;
        margin: auto;

        img {
            background-repeat: no-repeat;
            background-size: contain;
            // width: 100%;
            height: 240px;
        }
    }
}

#notify-popup {
    .modal-content {
        border-radius: 0.5rem !important;

        .modal-body {
            padding: 1rem !important;

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                text-align: center;
                color: #343434;
            }

            .btns {
                display: flex;
                align-items: center;
                justify-content: space-around;

                .btn {
                    width: 100% !important;
                    padding: 0.25 3rem !important;
                }

                .btn.yes {
                    background: #343434;
                    border: none !important;
                    outline: none !important;
                }
            }
        }
    }
}

.variation-wrapper {
    div {
        .variant-chip {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            min-width: fit-content;
            padding: 4px 12px;
            border: 1px solid #343434;
            border-radius: 4px;

            span {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 12px;
                text-transform: capitalize;
                color: #343434;
                white-space: nowrap;
            }
        }

        .variant-chip.selected {
            background: #DBBE82;

        }
    }
}

@media only screen and (min-width: 600px) {
    .ProductDetails {
        background-color: #fff;
    }

    .Description {
        border-radius: 0
    }

    // .arrow-back-icon {
    //     display: none;
    // }

    .Footer {
        background: #FFFFFF;
        box-shadow: none;
    }

    .ImageSlider {
        .slick-list {
            height: 240px;
            margin: auto;
        }
    }
}

@media all and (min-width: 992px) {
    .related-items-wrapper {
        overflow-x: hidden;
        flex-wrap: wrap;
    }
}

@media all and (max-width: 992px) {

    .no-scrollbar {
        scrollbar-color: transparent transparent;
    }

    .no-scrollbar::-webkit-scrollbar {
        height: 0px;
    }

    .no-scrollbar::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
    }

    .no-scrollbar::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0);
        -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
    }

    .no-scrollbar::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(0, 0, 0, 0);
    }
}

@media all and (max-width: 600px) {

    .Footer {
        background: #FFFFFF;
        box-shadow: none;
        height: 5rem;
        display: flex;
        align-items: center;
        bottom: 0;
        position: fixed;
        z-index: 999;
        width: 100%;
        padding-bottom: 5rem;
    }

    .Description {
        padding-bottom: 7rem
    }
}

.compatible-products-wrapper {
    flex-direction: column;
}

.comp-prod-chip {
    display: flex;
    flex-wrap: wrap;

    .product-chip {
        padding: 8px;
        border: 1px solid lightgray;
        border-radius: 8px;
        margin-right: 10px;
        margin-bottom: 8px;
        position: relative;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        text-transform: capitalize;
        color: #343434;
        white-space: nowrap;
        min-width: fit-content;
        padding: 4px 12px;
        border: 1px solid #343434;
        border-radius: 4px;
        box-sizing: border-box;
    }
}