.signup {
  p, a {
    font-size: 12px !important;
    line-height: 12px !important;
  }

  a {
    color: var(--gold-light);
    text-decoration: none
  }

  a.visited {
    color: blue !important;
  }

  #inline-checkbox-terms-and-conditions {
    padding: 0.75rem;
  }
  .tnc-wrapper {
    margin-left: 2.5rem;
    margin-top: 0.25rem;
  }
}