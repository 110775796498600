.tnc.container {
    .header {
        position: sticky;
        top: 0;
        height: 4rem;
        background: #eee;

        .row {
            height: 100%;
            align-items: center;
            text-align: center;
        }
    }

    h6 {
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 18px;
        color: #343434;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #343434;
    }
}

.tnc-pp-wrapper {
    .modal-content {
        border-radius: 0 !important;

        .modal-title {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            text-transform: capitalize;
            color: #343434;
        }
    }

    .modal-content .modal-body {
        padding: 0px 1rem 1rem 1rem;

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #343434;
        }
    }

    .modal-dialog {
        padding: 0 !important
    }
}