.input-password {
  input[type="password"]::-ms-reveal {
    display: none;
  }

  .form-label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-black);
  }

  .form-control {
    height: 30px;
    background-color: var(--black-bg) !important;
    color: #fff !important;
  }

  .reset-form-input {
    background-color: #f4f4f4;
  }

  .reset-form-input:focus {
    background-color: white;
  }

  .lock-Regular {
    position: absolute;
    top: 2.75rem;
    left: 1rem;
    z-index: 1;
  }

  .eye-Regular {
    position: absolute;
    top: 2.3rem;
    right: 0.5rem;
    z-index: 1;
  }
}
.login-form {
  .form-label {
    color: white;
  }
}
