.my-current-location {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
}

.arrow {
    position: absolute;
    right: -1rem;
    top: 0.5rem;
}

.MyCurrentLocation {
    .dropdown-menu.show {
        width: 100vw !important;
        max-width: 390px;
        margin: auto;
        height: 25vh;
        overflow: auto;
    }

    .location-selected {
        white-space: normal;
    }
}