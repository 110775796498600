.button-golden.pwa {
  width: 100%;
  /* width: 327px; */
  height: 38px;
  background: #dbbe82 !important;
  border-radius: 8px;
  outline: none;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #343434;

  &:disabled {
    color: #343434;
  }
  &:hover {
    color: #343434;
  }
}