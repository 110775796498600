.login {
  p {
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  a {
    color: var(--gold-light);
    text-decoration: none
  }
}

.login-form-signup-text {
  color: white;
}