.favourite-store-wrapper {
  overflow-x: auto;
}

.favourite-store {
  margin-bottom: 1.5rem;

  .card-body {
    padding: 0.5rem;

    .distance {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #CB9F47;
    }

    .store-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #343434;

    }
  }
}

.favorite-vsu-text {
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #343434;
}

.no-data-found-text {
  font-size: 0.75rem;
}

@media all and (max-width: 768px) {
  .aboutlinks ul {
    display: flex;
    overflow-x: auto;
  }

  .no-scrollbar {
    scrollbar-color: transparent transparent;
  }

  .no-scrollbar::-webkit-scrollbar {
    height: 0px;
  }

  .no-scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  }

  .no-scrollbar::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0);
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  }

  .no-scrollbar::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(0, 0, 0, 0);
  }
}

.stores {
  position: relative;
}
.stores.pointer-events {
  pointer-events: none;
}

.flagged-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}