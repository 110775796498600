.loader-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
}

@media only screen and (min-width: 600px) {
    .loader-wrapper {
        p {
            font-size: 1rem !important;
            font-weight: 600;
        }
    }
}