.PaymentProcessing {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 360px;
    text-align: center;
    padding: 2rem 0;
}

.continuing-payment-text {
    margin-top: 3rem;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #343434;
}

.payment-processing-text {
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    color: #343434;
    padding: 0.5rem;
}