@import './mixins.scss';

$carouselDotSize: 10px;

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

body {
  background-color: var(--bg-color);
  color: var(--color-text);
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.4em;

  .head{
    .title-container{
      span{
          font-weight: 700;
          font-size: 24px;
          line-height: 28px;
      }
    }
  }

  .normal-popup{
    background-color: var(--collapsable-bg);
  }

  @supports (-webkit-touch-callout: none) {
    .vh-100 {
      height: 100vh !important;
      height: -webkit-fill-available !important;
    }

    .min-vh-100 {
      min-height: 100vh !important;
      min-height: -webkit-fill-available !important;
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 800;
  color:var(--text-color)
}

.clickable {
  cursor: pointer;
}

.white-bg {
  background-color: var(--white-color) !important;
  color:var(--text-color-over-white);
}

// .gradient-bg {
//   @include grad-theme-primary();
//   .white-bg {
//     .btn-primary {
//       @include grad-theme-primary-reverse();
//     }
//   }

//   .btn-primary {
//     background: unset;
//     background-color:var(--button-primary-bg-plain)
//   }
// }

// .gradient-bg-accent{
//   @include grad-theme-accent()
// }


.white-text {
  color: var(--white-color);
}


.faded{
  opacity: 0.6;
}

.black-text{
  color:var(--color-text-light);
}

.w-350{
  width: 350px;
  max-width: calc(100vw - 30px);
}

.white-control-dropshadow{
  box-shadow: var(--white-control-dropshadow);
}


.has-horizontal-scroll{
  overflow-x: auto;
}

@include mq($global-breakpoint-md) {
  .welcome-carousel {
    .carousel-indicators{top:initial}
  }
  #welcome-logo {
    height: unset;
  }
}

legend{
  width:auto;
}

.has-basic-text {
  color: var(--color-text);

  a {
    color: var(--compos-light);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}



.modal-dialog {
  padding-left: 18px;
  padding-right: 18px;
}

.logout-modal {
  > .d-flex {
    padding: 44px 18px;
  }
  h3 {
    border-bottom: 1px solid var(--bg-light-grey);
    color: var(--color-text-light);
    font-size: 20px;
    font-weight: 700;
    line-height: 1em;
    margin: 0 0 35px;
    padding: 0 0 24px;
    width: 200px;
  }
  .btn {
    font-size: 14px;
    height: 50px;

    &.btn-primary {
      box-shadow: var(--white-control-dropshadow);
    }

    &:active {
      box-shadow: none;
      transform: translateY(2px);
    }

    + .btn {
      margin-top: 10px;
    }
  }
}

.w-320{
    width:320px;
}

.pay-card{
    height:175px;
    border-radius: 14px;
    top:-32px;
    .name{
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
    }
    .number{
        font-weight: 700;
        font-size: 26px;
        line-height: 30px;
    }
    .owner{
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
    }
}

.gradient-switch{
  .form-check-input{
    &:checked {
        background-image:url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e"), -moz-linear-gradient(-45deg, var(--bg-grad-accent-0) 0%, var(--bg-grad-accent-100) 100%);
        background-image:url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e"), -webkit-linear-gradient(-45deg,  var(--bg-grad-accent-0) 0%, var(--bg-grad-accent-100) 100%);
        background-image:url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e"), linear-gradient(135deg,  var(--bg-grad-accent-0) 0%, var(--bg-grad-accent-100) 100%);
    }
}
}

.modal-footer {
  .btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #cb9f47;
    --bs-btn-border-color: #cb9f47;
    --bs-btn-hover-bg: #CB9647;
    --bs-btn-hover-border-color: #CB9647;
    --bs-btn-active-bg: #cb9f4766;
    --bs-btn-active-border-color: #cb9f4766;
  }
}