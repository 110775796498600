.user-profile {
    .w-45 {
        width: 45%;
    }

    .sign-out-text {
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        line-height: 24px !important;
        color: #FB8D8D !important;
    }
}

.dropdown-menu.show {
    width: 100vw !important;
    max-width: 390px;
    margin: auto;
    height: 92vh;
    overflow: auto;
}

// .user-profile-container {
//     height: 100vh;
//     overflow-y: auto;
// }

.preview-profilePhoto {
    padding: 0.5rem 0.75rem;

    img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }
}

@media only screen and (min-width: 600px) {
    .dropdown-menu.show {
        height: 75vh;
    }
}