.feedbacks {
    min-height: 100vh;
    max-width: 30rem !important;
    position: relative;
    .card-text {
        font-size: 14px;
    }
    .btn {
        width: calc(100% - 3rem) !important;
        position: absolute;
        left: 0;
        bottom: 1.5rem;
        margin-left: 1.5rem !important;
    }
}

.border-primary.notes-wrapper {
    --bs-border-opacity: 1;
    border-color: #eee !important;
}