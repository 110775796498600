#uncontrolled-tab-example-tab-active,
#uncontrolled-tab-example-tab-completed,
#uncontrolled-tab-example-tab-favorite {
    border: 1px solid #A0A0A0;
    border-radius: 4px;
    color: #A0A0A0;
    max-width: 96%;
    padding: 0;
    margin: auto;
}

#uncontrolled-tab-example-tab-active.nav-link.active,
#uncontrolled-tab-example-tab-completed.nav-link.active,
#uncontrolled-tab-example-tab-favorite.nav-link.active {
    background: #343434;
    border: 1px solid #343434;
    border-radius: 4px;
    color: #fff;
}

.notification-box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
    background: #DBBE82;
    font-size: 14px;
    font-weight: 500;
    padding: 5px;
    border-radius: 5px;
}

.my-orders {
    ul {
        position: sticky;
        top: 4.75rem;
        z-index: 9999;
        background: #eee;
    }

    .cart-item-wrapper {
        .store_name {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
            color: #343434;
        }

        .product-quantity {
            cursor: pointer;
        }
    }

    .btn,
    .btn:focus,
    .btn:active,
    .btn:hover {
        background: #eee !important;
        border: none;
        outline: none;
    }

}

@media all and (max-width: 600px) {

    #uncontrolled-tab-example-tab-active,
    #uncontrolled-tab-example-tab-completed,
    #uncontrolled-tab-example-tab-favorite {
        font-size: 12px;
        width: 110px;
        margin: auto;
        max-width: 100%;
    }
}


.order-list-wrapper {
    box-shadow: 0px 0px 8px -3px rgba(0, 0, 0, 0.74);
    border-radius: 10px;
    padding: 8px;
    margin-bottom: 5px;

    .order-id,
    .store-name,
    .product-count {
        color: #343434;
        font-family: Archivo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        margin-bottom: 10px;
    }

    .order-eta {
        color: #343434;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;

        .curbside-button {
            border: 1px solid #CB9F47;
            color: #CB9F47;
            border-radius: 5px;
        }
    }

    .product-count {
        display: flex;
        justify-content: space-between;

        .product-details {
            transform: rotate(180deg);
            margin-right: 15px;
        }
    }
}

.completed-order-list {
    .completed-order-wrapper {
        display: flex;
        margin-top: 10px;
        .order-details {
            .store-name {
                color: #343434;
                font-family: Archivo;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
            }

            .date,
            .order-value {
                color: #A0A0A0;
                font-family: Archivo;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }
        }
    }

    .column:not(:last-child) {
        border-bottom: 1px solid gray;
    }
    .column {
        .order-again-action:hover {
            scale: 1.2;
        }
    }
}