.commonAuthScreen.pwa {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  // position: fixed;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  background-color: var(--black-bg);
  // max-width: 375px;
  color: #fff;
  // overflow: hidden;
  // height: 100vh;
  // max-height: 896px;

  .card {
    // height: 100%;
    // width: 375px;
    padding: 1rem 0;
    // overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none !important;
    columns: #fff;
    background-color: var(--black-bg);
    .card-img-top {
      width: 35% !important;
    }

    .card-title {
      text-align: center;
      margin: 1.5rem 0;
      color: #fff !important;

    }

    .card-back-arrow {
      position: absolute;
      left: 1.5rem;
      top: 2rem;
    }
  }

  .login-card {
    height: 100%;
    padding: 1rem 0;

    .card-title {
      text-align: center;
      margin: 1.5rem 0;
      color: #fff !important;
    }
  }
}