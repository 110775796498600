.product {
    background: #EFEFEF;
    cursor: pointer;

    .card_header {
        position: relative;
        height: 80px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        .heart-btn {
            position: absolute;
            right: 0;
            top: 0;
            padding: 0;
            background: #fff;
            border: none;
            outline: none;

            img {
                width: 20px;
                height: 17px;
            }
        }
    }

    p {
        margin-bottom: 0.5rem !important;
    }

    .card-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .desc {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        color: #A0A0A0;
    }

    .name {
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #343434;
    }

    .price {
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #CB9F47;
    }

    .textStyle {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: var(--black-bg);
    }
}

.top-10-products {
    div {
        max-width: 8rem;
    }

    .best-sellers {
        .card_header {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px;
            width: 60px;
            height: 60px;
            background: #FFFFFF;
            border: 1px solid #CACACA;
            border-radius: 100px;
            margin: auto;

            .img {
                height: 44px;
                width: 44px;
            }
        }

        .desc {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            color: #343434;
        }

        .name {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 14px;
            color: #343434;
        }

        .price {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: #343434;
        }
    }
}