.store {
    cursor: pointer;
    .card-text {
        margin: 0.5rem !important;
    }
    .card_header {
        img {
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
        }

        .name {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            color: #343434;
        }

        .store-favorite-icon {
            scale: 0.7;
        }
    }

    .distance {
        color: var(--gold-dark);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
    }

    .textStyle {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: var(--black-bg);
    }
}


@media only screen and (min-width: 600px) {
    .store>.card {
        // min-width: 300px;
        height: 240px;
    }
}