.cart-items-wrapper {
    // background: tomato;
    // height: 80vh;
    // overflow-y: auto;

    .store-name {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        color: #343434;
    }

    .cart-item-img {
        width: 56px;
        height: 60px;
    }

    .brand-name-wrapper {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .label-text {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: #A0A0A0;
    }

    .flavour {
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 20px;
        color: #343434;
        font-style: normal;
        font-weight: 800;
        font-size: 16px !important;
        line-height: 16px !important;
    }

    .cart-item-variation {
        color: var(--black, #343434);
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 18px;
    }

    .quantitySelected {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
    }

    .cart-btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1.65rem;
    }

    .btn,
    .btn:focus,
    .btn:active,
    .btn:hover {
        background: #eee !important;
        border: none;
        outline: none;
    }
}

.cart-header {
    position: sticky;
    top: 0;
    z-index: 999;
    background: #eee;

    .btn-empty {
        background: #CB9F47;
        width: 100%;
        outline: none !important;
        border: none !important;
    }
}

.eta {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #343434;
}

.total-price {
    p {
        margin-bottom: 0.5rem;
    }

    .text-total {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #A0A0A0;
    }

    .text-price {
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 18px;
        color: #343434;
        margin-bottom: 0 !important;
    }
}

.cart-subtotal-details {
    padding-bottom: 5rem;

    .price-item {
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #343434;
        }
    }

    .total-price-item {
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 24px;
        color: #343434;
    }
}

.btn-paynow {
    background: #343434 !important;
    width: 100%;
    outline: none !important;
    border: none !important;
}

.btn-validate {
    background: #DBBE82 !important;
    outline: none !important;
    border: none !important;
}

.pickup-type {
    .form-check-input:checked {
        background-color: #343434;
        border-color: #343434;
    }
}

@media only screen and (min-width: 768px) {
    .total-price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.5rem;

        p {
            font-size: 16px;
        }
    }

    .eta-wrapper {
        padding: 2rem 0;
    }
}


@media only screen and (max-width: 768px) {
    .positionfixed {
        height: 5rem;
        display: flex;
        align-items: center;
        bottom: 0;
        position: fixed;
        z-index: 999;
        background: #eee;
        width: 100%;
    }
}

@media only screen and (max-width: 576px) {
    .positionfixed {
        background: #fff !important;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }
}

.deal-applied {
    display: block;
    font-size: 16px;
    color: #DBBE82;
    font-weight: 500;
    position: absolute;
    bottom: -10px;
}