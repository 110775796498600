.home {
    background: #EFEFEF;

    .bg-header {
        background-color: #D9D9D9;
    }

    .near-you-text {
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #343434;
    }
}

.input-search-box .form-control {
    padding: 0.5rem 2rem;
    background-color: #fff !important;
    padding-right: 50px;
    background: #fff url("../../assets/images/search.svg") no-repeat;
    background-size: 18px;
    background-position: 0.4rem;
}


.my-location-title {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: -0.24px;
    color: #343434;
}

/** STORES SCROLL */

.home.container {
    height: 100vh;
    overflow: hidden;

    .stores {
        height: 75vh;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.flagged-text-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    height: 32px;
    background: #343434;
    border-radius: 4px;

    span {
        font-style: normal;
        font-weight: 800;
        font-size: 10px;
        line-height: 10px;
        color: #FFFFFF;
    }
}

.home-cart-icon-wrapper {
    position: relative;
    // width: 60%;
    cursor: pointer;

    .cartItemTotal {
        position: absolute;
        top: -8px;
        left: 1rem !important;
        background: #0288D1;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        color: #fff;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.disable-flagged-map {
    pointer-events: none;
    opacity: 0.5;
}