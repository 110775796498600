.commonAuthScreen {
  a {
    color: var(--gold-light);
    margin-left: 0.25rem;
  }
  .signup {
    p {
      font-size: 12px;
      line-height: 12px;
    }
    a {
      color: var(--gold-light);
      font-size: 14px;
    }
  }
}
