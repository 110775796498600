.search-address-input{
  div{
    input{
      padding-left: 45px;
    }
  }
}
.search-icon{
  position: relative;
  top: 30px;
  left: 10px;
  z-index: 999;
}