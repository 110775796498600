.my-order-details {
    .storeName {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        color: #343434;

        p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .price-section {
        .price-item {
            p {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: #343434;
            }
        }

        .total-price-item {
            font-style: normal;
            font-weight: 800;
            font-size: 14px;
            line-height: 24px;
            color: #343434;
        }
    }

    .your-cart-text,
    .store-text {
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 20px;
        color: #343434;
    }

    .btn,
    .btn:focus,
    .btn:active,
    .btn:hover {
        background: #eee !important;
        border: none;
        outline: none;
    }
}