.confirm-address-wrapperXX {
    background: rgb(255, 255, 255);
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 1rem;
    // width: 100% !important;
    margin: auto;
    border-radius: 32px 32px 0px 0px;
    z-index: 9999;
    // left: 50%;

    p {
        margin-bottom: 0;
        font-size: 14px;
    }

    .current-location>p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-top: 3px;
        text-align: left;
    }

    .my-location-details {
        padding: 0 !important;
        margin-bottom: 1.5rem;
    }

}

#overlay {
    position: fixed;
    // display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
}


@media only screen and (min-width: 576px) {
    .confirm-address-wrapperXX {
        left: 100%;
        transform: translateX(-100%);
        width: 20vw !important;
        min-width: 360px;
        height: 100vh;
        border-radius: 0;
    }
}