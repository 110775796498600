.applicationScreenInput {
  margin-bottom: 2.25rem;
  position: relative;

  label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-black);
  }

  .form-control {
    height: 45px;
  }

  .form-label {
    width: 100%;
    overflow-x: clip;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--color-grey) !important;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--color-grey) !important;
  }

  ::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--color-grey) !important;
  }
}

.applicationTextarea {
  margin-bottom: 2.25rem;
  position: relative;

  label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-black);
  }

  .form-label {
    width: 100%;
    overflow-x: clip;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--color-grey) !important;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--color-grey) !important;
  }

  ::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--color-grey) !important;
  }
}

.applicationScreenInput {
  margin-bottom: 1.25rem;
  position: relative;
  label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-black);
  }
  .form-control {
    height: 30px;
    width: 327px;
    background-color: var(--black-bg) !important;
    color: #fff !important;
    &.active {
      background-color: #fff;
    }
  }
  .form-label {
    width: 100%;
    overflow-x: clip;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
textarea.form-control{
  height: 60px !important;
}

.login-form {
  .form-label {
    color: white;
  }
}