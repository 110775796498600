.dropdown-toggle::after {
  display: none !important;
}

// .homepage-settings {
//   .dropdown-menu.show {
//     position: fixed !important;
//     width: 100%;
//     background: red;
//     max-width: 375px;
//   }
// }